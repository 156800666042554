import {useRef, useEffect, useState, MutableRefObject} from 'react';
import {trailSlash} from '../utils';

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => clearTimeout(handler);
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function usePortalTarget(id: string): HTMLElement | null {
  const elemRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // Look for dom root element
    const parentElem = document.querySelector(`#${id}`);
    // Add the created div to the parent
    if (parentElem && elemRef.current) {
      parentElem.appendChild(elemRef.current);
    }
    // Clean up the dom created div
    return () => (elemRef as MutableRefObject<HTMLElement>).current.remove();
  }, [id]);

  // Lazy initialization

  // Taken from the React docs:
  // "Conceptually, you can think of refs as similar to instance variables in a class.
  // Unless you’re doing lazy initialization, avoid setting refs during rendering —
  // this can lead to surprising behavior. Instead, typically you want to modify refs in event handlers and effects."
  //
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily

  function getRootElem() {
    if (elemRef && !elemRef.current) {
      elemRef.current = document.createElement('div');
    }
    return elemRef.current;
  }

  return getRootElem();
}

export function useMessage<T>(
  receiveEvent: string,
  targetOrigin: string[] = [],
  cb: (event: MessageEvent<{event: string} & T>) => void
): void {
  useEffect(() => {
    function onMessage(event: MessageEvent<{event: string} & T>) {
      if (
        targetOrigin[0] !== '*' &&
        !targetOrigin.includes(trailSlash(event.origin))
      ) {
        console.error(
          'Origin does not match with target origin:',
          event.origin,
          'with target origins:',
          targetOrigin,
          'for event:',
          event
        );
      } else {
        if (event.data && receiveEvent === event.data.event) {
          cb(event);
        }
      }
    }
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);
}

export function sendMessage<T>(
  data: {event: string} & T,
  targetOrigin: string[] = [],
  win: Window = window.parent
): void {
  targetOrigin.forEach((t) => {
    console.log(`Send ${data.event} to:`, t);
    win.postMessage(data, t);
  });
}
