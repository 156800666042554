import {Logger, Level, Format, FormatData} from './logger';

export * from './logger';

export const customFormat: Format = (formatData: FormatData) => {
  const {appName, timestamp, level} = formatData;
  return `${timestamp} [${appName}] ${level}:`;
};

export function createLogger(appName: string, level = Level.DEBUG): Logger {
  return new Logger(appName, level, customFormat);
}
