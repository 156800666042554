export type LevelName = 'debug' | 'info' | 'warning' | 'error' | 'critical';

export enum Level {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
}

export type Message = string | Record<string, any>;

export type Format = (formatData: FormatData) => string;

export type FormatData = {
  appName: string;
  timestamp: string;
  level: Level;
};

export function getLevelByName(levelName: LevelName): Level {
  switch (levelName) {
    case 'debug':
      return Level.DEBUG;
    case 'info':
      return Level.INFO;
    case 'warning':
      return Level.WARNING;
    case 'error':
      return Level.ERROR;
    case 'critical':
      return Level.CRITICAL;
    default:
      return Level.DEBUG;
  }
}

export class Logger {
  private readonly appName: string;
  private readonly level: Level;
  private readonly format: Format;

  constructor(appName: string, level: Level, format?: Format) {
    this.appName = appName;
    this.level = level;
    this.format = format || (() => '');
  }

  private canPrint(reqLevel: Level): boolean {
    return reqLevel >= this.level;
  }

  private buildFormatData(level: Level): FormatData {
    const now = new Date();
    const timestamp =
      `${now.getHours().toString().padStart(2, '0')}:` +
      `${now.getMinutes().toString().padStart(2, '0')}:` +
      `${now.getSeconds().toString().padStart(2, '0')}`;
    return {
      appName: this.appName,
      timestamp,
      level,
    };
  }

  private writeMessage(level: Level, ...messages: Message[]): void {
    if (this.canPrint(level)) {
      const prefix = this.format(this.buildFormatData(level));
      console.log(prefix, ...messages);
    }
  }

  public debug(...messages: Message[]): void {
    this.writeMessage(Level.DEBUG, ...messages);
  }

  public info(...messages: Message[]): void {
    this.writeMessage(Level.INFO, ...messages);
  }

  public warning(...messages: Message[]): void {
    this.writeMessage(Level.WARNING, ...messages);
  }

  public error(...messages: Message[]): void {
    this.writeMessage(Level.ERROR, ...messages);
  }

  public critical(...messages: Message[]): void {
    this.writeMessage(Level.CRITICAL, ...messages);
  }
}
