export const urlRegex =
  /^(https?:\/\/|ftps?:\/\/)?([a-z0-9%-]+\.)+([a-z0-9-]+)?(:(\d{1,5}))?(\/([a-z0-9ÅMŽÕÑ|\-._~:/?#[\]@!$&'()*+,;=% ]+)?)?$/i;
export const emailRegex = /[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.+[a-zA-Z0-9]{2,4})+/;

export function trailSlash(str?: string): string {
  if (!str) return '/';
  if (!str.endsWith('/')) return `${str}/`;
  return str;
}

export function removeTrailSlash(str?: string): string {
  if (!str) return '';
  if (str.endsWith('/')) return str.slice(0, -1);
  return str;
}

export function parseFormattedStringNumber(str: string): number {
  const s = str.replace(/,/g, '');
  return parseFloat(s);
}

export function setPrecision(d = ''): string {
  if (!d) return d;
  let dec = '00';
  const parts = d.split('.');
  if (parts[1]) {
    if (parts[1].length === 1) dec = `${parts[1][0]}0`;
    else dec = `${parts[1]}`;
  }
  return `${parts[0]}.${dec}`;
}

export function getStr(value?: string, def = ''): string {
  return typeof value === 'string' ? value : def;
}
